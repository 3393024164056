<template>
  <form class="catalog-filter-extend" @submit.prevent="submit">
    <ul class="catalog-filter-extend__accordion-list" data-accordion-container data-accordion-mode="multi">
      <li v-for="field in accordionFields"
          class="catalog-filter-extend__accordion-item"
          data-accordion-element
          :data-accordion-field="field.key"
      >
        <a href="javascript:void(0);" class="catalog-filter-extend__accordion-link" data-accordion-link>
          <span class="catalog-filter-extend__accordion-text">
            {{ field.label }}
          </span>

          <span class="catalog-filter-extend__accordion-icon">
            <span class="catalog-filter-extend__icon-line"></span>
            <span class="catalog-filter-extend__icon-line"></span>
          </span>
        </a>

        <div class="catalog-filter-extend__accordion-block" data-accordion-block>
          <div class="catalog-filter-extend__accordion-block-inner">
            <div class="catalog-filter-extend__filter-values-block">
              <input
                  v-if="field.view_type === 'dynamic-checkbox-list'"
                  :id="field.key"
                  type="text"
                  autocomplete="off"
                  class="catalog-filter-extend__text-input"
                  v-model="field.contains_filter"
                  placeholder="Поиск"
                  @input="debounceChangeWithReaccordion(field.key)"
              >
              <div class="catalog-filter-extend__filter-values-count"
                   v-if="field.view_type === 'dynamic-checkbox-list' && field.filtered_count > 50"
              >
                50 из {{ field.filtered_count }}
              </div>


              <BaseFieldSwitch
                  v-if="field.mode_label"
                  :input-id="`${field.key}_mode`"
                  v-model="field.mode_value"
                  :label="field.mode_label"
                  @change="activateSwitch(field.key)"
              />
            </div>


            <StorageCheckboxList
                v-if="field.key === 'storage'"
                :input-id="field.key"
                :current-values="field.current_value"
                :possible-values="field.values"
                :disabled-values="field.disable_values"
                :check-all="true"
                @change="debounceChange()"
            />

            <BaseFieldCheckboxListChunked
                v-else-if="isAccordionCheckBoxList(field)"
                :input-id="field.key"
                :current-values="field.current_value"
                :possible-values="field.values"
                :disabled-values="field.disable_values"
                :has-range="field.has_range"
                :bus="bus"
                @change="field.key === 'technology' ? change() : debounceChange()"
                @recomputedColumns="reaccordionField"
            />

            <preloader v-if="totalPreloader"/>
          </div>
        </div>
      </li>
    </ul>

    <div class="catalog-filter-extend__ending-wrapper">
      <div class="catalog-filter-extend__ending-fields">
        <div v-for="field in endingFields"
             class="catalog-filter-extend__ending-field"
             :class="{'_checkbox': field.view_type === 'checkbox'}"
        >
          <BaseRangeSlider
              v-if="field.view_type === 'range'"
              :field-id="field.key"
              :label="field.label"
              :borders="field.borders"
              :state-value="field.current_value"
              :disabled="field.disabled"
              @change="change"
          />

          <BaseFieldCheckbox
              v-if="field.view_type === 'checkbox'"
              :label="field.label"
              :input-id="field.key"
              :value="field.current_value"
              v-model="field.current_value"
              :disabled="field.disabled"
              @change="change"
          />
        </div>
      </div>

      <div class="catalog-filter-extend__button-wrapper">
        <button
            type="button"
            class="catalog-filter-extend__button button button__secondary"
            @click="resetFilters"
        >
          Сбросить фильтры
        </button>
        <button
            type="submit"
            :disabled="disableSubmit"
            class="catalog-filter-extend__button button">
          Применить фильтры
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import BaseFieldCheckboxList from '../parts/fields/BaseFieldCheckboxList.vue';
import BaseFieldCheckboxListChunked from '../parts/fields/BaseFieldCheckboxListChunked.vue';
import StorageCheckboxList from '../parts/fields/StorageCheckboxList.vue';
import BaseRangeSlider from '../parts/fields/BaseRangeSlider.vue';
import Vue from "vue";
import BaseFieldCheckbox from '../parts/fields/BaseFieldCheckbox.vue';
import Preloader from "../parts/Preloader.vue";
import BaseFieldSwitch from '../parts/fields/BaseFieldSwitch.vue';


export default {
  name: "TheFilterParamExtend",
  components: {
    StorageCheckboxList,
    Preloader,
    BaseFieldCheckboxList,
    BaseFieldCheckboxListChunked,
    BaseRangeSlider,
    BaseFieldCheckbox,
    BaseFieldSwitch
  },
  data() {
    return {
      // создаем экземпляр вью чтобы слушать события сброса фильтров в дочернем элементе
      bus: new Vue()
    }
  },
  computed: {
    ...mapState('filterParam', {
      'fields': 'fields',
    }),
    ...mapState('catalogFilter', {
      'totalProducts': 'totalProducts',
      'totalPreloader': 'totalPreloader'
    }),
    ...mapGetters('filterParam', {
      'inchField': 'inchField'
    }),
    ...mapGetters('catalogFilter', {
      'hideStorages': 'hideStorages'
    }),
    accordionFields() {
      const fields = this.fields
          .filter((field) => field.group === 'accordion' || field.group_alt === 'accordion')
          .filter((field) => field.values.length || field.view_type === 'dynamic-checkbox-list')
          .filter((field) => field.key !== 'storage' || !this.hideStorages);

      if (this.inchField) {
        const excludeType = this.inchField.current_value ? 'metric' : 'inch';
        return fields.filter((field) => field.size_type !== excludeType);
      }

      return fields;
    },

    endingFields() {
      return this.fields.filter((field) => field.group === 'ending');
    },
    disableSubmit() {
      return !this.totalProducts;
    },
  },
  methods: {
    ...mapMutations('filterParam', {
      'setCurrentValue': 'setCurrentValue',
    }),
    ...mapActions('filterParam', {
      'updatePossibleFields': 'updatePossibleFields',
      'debounceUpdatePossibleFields': 'debounceUpdatePossibleFields',
      'fetchProducts': 'fetchProducts',
      'resetFields': 'resetFields',
    }),
    isAccordionCheckBoxList(field) {
      const types = ['checkbox-list', 'dynamic-checkbox-list'];
      if ((field.group_alt === 'accordion' && types.includes(field.view_type_alt))) {
        return true;
      }

      return (field.group === 'accordion' && types.includes(field.view_type));
    },

    change() {
      this.updatePossibleFields();
    },
    activateSwitch(key) {
      if (key === 'technology') {
        this.updatePossibleFields();
      }
    },
    debounceChange() {
      this.debounceUpdatePossibleFields();
    },
    async debounceChangeWithReaccordion(id) {
      this.debounceUpdatePossibleFields();

      const block = document.querySelector(`[data-accordion-field="${id}"] [data-accordion-block]`);
      const content = block.children[0];
      let contentHeight = content.clientHeight;

      const interval = setInterval(() => {
        if (contentHeight !== content.clientHeight) {
          contentHeight = content.clientHeight;
          this.reaccordionField(id);
          clearInterval(interval);
        }
      }, 300);
      //
      setTimeout(() => {
        clearInterval(interval);
      }, 5000)
    },
    submit() {
      if (this.totalProducts) {
        this.fetchProducts();
      }
    },
    resetFilters() {
      // Отправляем событие сброса фильтров
      this.bus.$emit('resetFilters');
      this.resetFields();
    },
    reaccordionField(id) {
      const element = document.querySelector(`[data-accordion-field="${id}"]`);
      if (element) {
        element.dispatchEvent(new Event('checkHeight'));
      }
    }
  },
  mounted() {
    document.dispatchEvent(new CustomEvent('NeedInitAccordion'));
  }
}
</script>
