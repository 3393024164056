var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stat-page__inner-content" }, [
    _c("div", { staticClass: "stat-page__row" }, [
      _c(
        "div",
        { staticClass: "stat-page__graph_large" },
        [_c("BaseGraph", { attrs: { "graph-key": "clients_by_day_graph" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "stat-page__graph_large" },
        [
          _c("ProductTypedGraph", {
            attrs: {
              "graph-key": "client_prices_by_day_graph",
              title: "Цены по клиентам",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "stat-page__graph_large" },
        [
          _c("ProductTypedGraph", {
            attrs: {
              "graph-key": "client_prices_total_by_day_graph",
              title: "Всего цен по клиентам",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "stat-page__row" },
      [
        _c("ProductCountTable", {
          attrs: {
            name: "Просмотры клиентов",
            "table-key": "client_views_table",
          },
        }),
        _vm._v(" "),
        _c("ProductCountTable", {
          attrs: {
            name: "Добавления в корзину",
            "table-key": "client_carts_table",
          },
        }),
        _vm._v(" "),
        _c("ProductCountTable", {
          attrs: {
            name: "Оформления заказов",
            "table-key": "'client_orders_table",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }