import StatClientService from '../../services/statClient.service';

const StatClientModule = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    statData: {
      client_actions: [],
      total_client_actions: 0,
    },
    clientList: [],
    currentClient: null,
  },
  actions: {
    async initStatClientPage({ dispatch }) {
      dispatch('loadClientList');
    },
    async loadClientList({ commit }) {
      const result = await StatClientService.fetchClientList();
      commit('setClientList', result.data);
    },
    async setCurrentClient({ commit }, currentClient) {
      await commit('setCurrentClient', currentClient);
    },
    async getClientActions({ state, commit }, params) {
      const result = await StatClientService.fetchClientActions(state.currentClient, params);
      commit('updateStateData', { key: 'client_actions', data: result.data.data });
      commit('updateStateData', { key: 'total_client_actions', data: result.data.total });
    },
    async updateStatData({ dispatch }, { statDataKey, params }) {
      switch (statDataKey) {
        case 'client_actions':
          dispatch('getClientActions', params);
          break;
        default:
          console.log(`Undefined key ${statDataKey}`);
      }
    },
  },
  mutations: {
    updateStateData(state, { key, data }) {
      state.statData[key] = data;
    },
    setClientList(state, clients) {
      state.clientList = clients;
    },
    setCurrentClient(state, currentClient) {
      state.currentClient = currentClient;
    },
  },
};

export default StatClientModule;