<template>
  <div class="vue-field vue-field_select">
    <label v-if="label" :for="fieldId">
      {{ label }}
    </label>
    <div data-vue-custom-select class="custom-select custom-select_type_single custom-select_type_filterable"
         :class="{
            _open: isOpen,
            _disabled: disabled,
         }"
    >
      <div class="custom-select__wrapper">
        <div class="custom-select__field-wrapper">
          <input type="text" class="custom-select__field"
                 :value="showedValue()"
                 autocomplete="off"
                 :id="fieldId"
                 @click="openable"
                 @input="filter"
                 :placeholder="placeholder"
          >
          <div class="custom-select__arrow-icon custom-select__arrow-icon_absolute">
            <ArrowSmall/>
          </div>
        </div>
        <div class="custom-select__content-wrap custom-select__content-wrap_openable">
          <ul class="custom-select__list">
            <li v-if="!noDefault" class="custom-select__item">
              <input
                  class="custom-select__input custom-select__input_radio"
                  type="radio"
                  :value="defaultValue.value"
                  :id="uniqueId(fieldId)"
                  :name="fieldId"
                  @change="change"
              >
              <label :for="uniqueId(fieldId)" class="custom-select__label">
                <span class="custom-select__choice-name">
                  {{ defaultValue.name }}
                </span>
              </label>
            </li>
            <li v-for="item in values" class="custom-select__item" :class="{'_hidden': isHidden(item)}">
              <input
                  class="custom-select__input custom-select__input_radio"
                  type="radio"
                  :name="fieldId"
                  :value="valueAsString(item)"
                  :id="uniqueId(fieldId, valueAsString(item))"
                  :disabled="checkDisabled(valueAsString(item))"
                  @change="change"
              >
              <label :for="uniqueId(fieldId, valueAsString(item))" class="custom-select__label">
                <span class="custom-select__choice-name">
                  {{ nameAsString(item) }}
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowSmall from '../../icons/ArrowSmall.vue';

export default {
  name: "BaseFieldSelectFilterable",
  components: {
    ArrowSmall
  },
  props: {
    // Уникальный id селекта
    fieldId: {
      type: String,
      required: true
    },
    /*
      Все возможные значения селекта
      - список объектов с полями 'name' и 'value'
    */
    values: {
      type: Array,
      required: true
    },
    currentValue: {
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    disableValues: {
      type: Array,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    // true - не добавлять null => 'Все'
    noDefault: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    defaultValue: {
      type: Object,
      required: false,
      default: () => {
        return {
          name: 'Все',
          value: null
        }
      }
    },
  },
  data() {
    return {
      isOpen: false,
      hiddenValues: [],
      filterableString: '',
      currentValueString: ''
    }
  },
  methods: {
    openable(e) {
      e.preventDefault();
      this.isOpen = !this.isOpen;
    },
    change(e) {
      const element = e.target;
      const value = element.value;
      this.isOpen = false;
      this.filterableString = '';
      const item = this.getItemByValue(value);
      this.currentValueString = this.nameAsString(item);
      this.filterableString = '';
      this.hiddenValues = [];

      this.$emit('input', value);
    },
    showedValue() {
      if (!this.currentValueString && !this.filterableString && this.currentValue) {
        this.currentValueString = this.nameAsString(this.getItemByValue(this.currentValue))
      } else if (!this.currentValue && this.currentValueString) {
        this.currentValueString = '';
      }

      if (this.currentValueString) {
        return this.currentValueString;
      } else {
        return this.filterableString;
      }
    },
    getItemByValue(value) {
      return this.values.find((item) => item.value === value);
    },
    uniqueId(prefix, value) {
      value = value === undefined ? 'all' : value;
      return `${prefix}_${value}`;
    },
    valueAsString(item) {
      return item.value !== undefined ? item.value : '';
    },
    nameAsString(item) {
      return item.name !== undefined ? item.name : '';
    },
    checkDisabled(value) {
      return this.disableValues ? this.disableValues.includes(value) : false;
    },
    abortInput(e) {
      e.preventDefault();
      e.target.value = '';
    },
    filter(e) {
      const element = e.target;
      const value = element.value.trim().toLowerCase();
      if (value === '') {
        this.hiddenValues.splice(0, this.hiddenValues.length);
      } else {
        this.values.forEach((item) => {
          const includes = item.name.toString().trim().toLowerCase().includes(value);
          const alreadyHidden = this.hiddenValues.includes(item.value);
          if (includes && alreadyHidden) {
            this.hiddenValues.splice(this.hiddenValues.indexOf(item.value), 1);
          } else if (!includes && !alreadyHidden) {
            this.hiddenValues.push(item.value)
          }
        })
      }

      this.filterableString = value;
      this.currentValueString = '';
      element.value = this.filterableString;
    },
    isHidden(item) {
      return this.hiddenValues.includes(item.value);
    },
  },
  mounted() {
    const instance = this;
    const el = this.$el.querySelector('[data-vue-custom-select]');
    document.addEventListener('click', (e) => {
      if (instance.isOpen && !(el === e.target || el.contains(e.target))) {
        instance.isOpen = false;
      }
    });
  },
}
</script>