import FilterParamService from '../../services/filterParam.service';
import FieldsUpdater from '../../parts/fields-updater';
import FieldsPayload from '../../parts/fields-payload';

const FilterParam = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    extend: false,
    fields: [],
    filterMode: '',
    enableSecondAxis: false
  },
  getters: {
    inchField: (state, getters, rootState) => {
      if (rootState.catalogFilter.mode === 'param') {
        return state.fields.find((field) => field.group === 'main-inch');
      }

      return null;
    },
  },
  actions: {
    // Первоначальная загрузка. Теперь поумолчанию только в наличии
    async loadPossibleFields({ state, dispatch, commit, rootState }, { filterMode }) {
      if (!state.loading && !state.loaded) {
        commit('setFilterMode', filterMode);

        const params = { is_first_loaded: true, avail: true, inch: false };
        if (rootState.catalogFilter.settings.categoryId) {
          params.category_id = rootState.catalogFilter.settings.categoryId;
        }

        await dispatch('fetchPossibleFields', params);
        commit('setLoaded', true);
      }
    },

    // Подготовка к первоначальному стягиванию полей
    // Обработка результата после первоначалього стягивания
    async fetchPossibleFields({ dispatch, commit, rootState }, payload) {
      dispatch('addLoading');

      if (rootState.catalogFilter.settings.categoryId) {
        payload.category_id = rootState.catalogFilter.settings.categoryId;
      }

      const result = await dispatch('getPossibleFieldsResponse', payload);
      if (result) {
        const { count, fields } = result.data;
        commit('catalogFilter/setTotalProducts', count, { root: true });

        if (fields) {
          commit('setFields', fields);
        }
      }

      dispatch('removeLoading');
    },

    // Подготовка к обновлению полей
    // Обработка результата, выставление блокировок значений
    async updatePossibleFields({ state, dispatch, commit }) {
      dispatch('addLoading');

      const result = await dispatch('getPossibleFieldsResponse');
      if (result) {
        const { count, fields } = result.data;
        commit('catalogFilter/setTotalProducts', count, { root: true });

        if (fields) {
          const updatedFields = FieldsUpdater.updateFields(state.fields, fields);
          await commit('setFields', updatedFields);
          // await dispatch('checkHidePrice');
        }
      }

      dispatch('removeLoading');
    },

    // Формирование запроса на бэк
    async getPossibleFieldsResponse({ state, rootState, dispatch }, payload) {
      try {
        const { productType } = rootState.catalogFilter.settings;
        payload = payload || {};
        if (payload.reset) {
          // Исключительные поля
          payload.inch = false;
          payload.avail = true;
        } else {
          payload = Object.assign(payload, FieldsPayload.getPayload(state.fields));
        }

        payload.fetch_short_only = false;

        if (rootState.catalogFilter.settings.categoryId) {
          payload.category_id = rootState.catalogFilter.settings.categoryId;
        }

        return await FilterParamService.fetchPossibleFields(productType, payload);
      } catch (error) {
        dispatch('notifications/showErrorNotification', '', { root: true });
        return null;
      }
    },

    // Перед отправкой на апдейт - ждем
    async debounceUpdatePossibleFields({ dispatch }) {
      this.debounceOrder = this.debounceOrder ? this.debounceOrder + 1 : 1;
      setTimeout(() => {
        this.debounceOrder -= 1;
        if (!this.debounceOrder) {
          dispatch('updatePossibleFields');
        }
      }, 1000);
    },

    // Стянуть товары
    async fetchProducts({ state, dispatch, commit, rootState }) {
      commit('catalogFilter/setProductPreloader', true, { root: true });

      const payload = FieldsPayload.getPayload(state.fields);
      payload.order = rootState.catalogFilter.currentOrder;
      payload.enable_second_axis = state.enableSecondAxis;

      const { productType } = rootState.catalogFilter.settings;

      if (rootState.catalogFilter.settings.categoryId) {
        payload.category_id = rootState.catalogFilter.settings.categoryId;
      }
      const result = await FilterParamService.fetchProducts(productType, payload);

      if (result) {
        await dispatch('catalogFilter/replaceCatalogContent', result.data, { root: true });
      }

      dispatch('checkHidePrice');
      commit('catalogFilter/setProductPreloader', false, { root: true });
      document.dispatchEvent(new Event('DOMContentMutated'));
    },

    async fetchCsv({ state, dispatch, commit, rootState }) {
      commit('catalogFilter/setProductPreloader', true, { root: true });

      const payload = FieldsPayload.getPayload(state.fields);
      payload.order = rootState.catalogFilter.currentOrder;
      payload.enable_second_axis = state.enableSecondAxis;
      payload.category_id = rootState.catalogFilter.settings.categoryId;

      const { productType } = rootState.catalogFilter.settings;

      const result = await FilterParamService.fetchCsv(productType, payload);

      if (rootState.catalogFilter.settings.categoryId) {
        payload.category_id = rootState.catalogFilter.settings.categoryId;
      }

      if (result.data.success) {
        dispatch('notifications/showSuccessNotification', 'Запрос на генерацию CSV успешно отправлен', { root: true });
      } else {
        dispatch('notifications/showErrorNotification', 'Произошла ошибка', { root: true });
      }

      commit('catalogFilter/setProductPreloader', false, { root: true });
    },

    async resetFields({ dispatch }) {
      await dispatch('fetchPossibleFields', { is_first_loaded: true, reset: true, inch: false });
    },

    async checkHidePrice({ state, rootState, dispatch }) {
      const hidePriceField = state.fields.find((field) => field.key === 'hide_price');
      if (hidePriceField && hidePriceField.current_value !== rootState.catalogFilter.hidePrice) {
        dispatch('catalogFilter/updateHidePrice', hidePriceField.current_value, { root: true });
      }
    },

    switchExtend({ state, commit }) {
      commit('setExtend', !state.extend);
    },
    setEnableSecondAxis({commit}, isEnable) {
      commit('setEnableSecondAxis', isEnable)
    },
    async addLoading({ state, commit }) {
      if (state.filterMode === 'chain') {
        commit('setLoading', true);
      } else if (state.loaded) {
        commit('catalogFilter/setTotalPreloader', true, { root: true });
      }
    },
    async removeLoading({ state, commit }) {
      if (state.filterMode === 'chain') {
        commit('setLoading', false);
      } else {
        commit('catalogFilter/setTotalPreloader', false, { root: true });
      }
    },

    handleCustomField({ dispatch }, field) {
      switch (field.key) {
        case 'hide_price':
          dispatch('checkHidePrice');
          break;
      }
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setLoaded(state, loaded) {
      state.loaded = loaded;
    },
    setExtend(state, extend) {
      state.extend = extend;
    },
    setFilterMode(state, filterMode) {
      state.filterMode = filterMode;
    },
    setFields(state, fields) {
      state.fields = fields;
    },
    setEnableSecondAxis(state, isEnable) {
      state.enableSecondAxis = isEnable;
    },
    setCurrentValue(state, { fieldKey, value }) {
      const findedField = state.fields.find((field) => field.key === fieldKey);
      if (findedField) {
        const index = state.fields.indexOf(findedField);
        state.fields[index].current_value = value;
      }
    },
  },
};

export default FilterParam;