import AutoParamService from '../../services/autoParam.service';
import FieldsUpdater from '../../parts/fields-updater';
import FieldsPayload from '../../parts/fields-payload';

const AutoParam = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    extend: false,
    filterMode: 'disable',
    currentModification: {
      name: null,
      info: [],
    },
    autoFields: {
      manufacturer: {
        label: 'Марка',
        key: 'auto-manufacturer',
        values: [],
        current_value: null,
        placeholder: 'Выберете марку',
      },
      model: {
        label: 'Модель',
        key: 'auto-model',
        values: [],
        current_value: null,
        placeholder: 'Выберете модель',
      },
      generation: {
        label: 'Поколение',
        key: 'auto-generation',
        values: [],
        current_value: null,
        placeholder: 'Выберете поколение',
      },
      modification: {
        label: 'Модификация',
        key: 'auto-modification',
        values: [],
        current_value: null,
        placeholder: 'Выберете модификацию',
      },
    },
    paramFields: [],
  },
  getters: {
    arrayAutoFields(state) {
      return [
        state.autoFields.manufacturer,
        state.autoFields.model,
        state.autoFields.generation,
        state.autoFields.modification,
      ];
    },
    enableButton(state) {
      return !!state.autoFields.modification.current_value;
    },
  },
  actions: {
    async addLoading({ state, commit }) {
      if (state.filterMode === 'chain') {
        commit('setLoading', true);
      } else {
        commit('catalogFilter/setTotalPreloader', true, { root: true });
      }
    },
    async removeLoading({ state, commit }) {
      if (state.filterMode === 'chain') {
        commit('setLoading', false);
      } else {
        commit('catalogFilter/setTotalPreloader', false, { root: true });
      }
    },
    async loadManufactures({ state, dispatch, commit, rootState }) {
      if (!state.loading && !state.loaded) {
        commit('setFilterMode', rootState.catalogFilter.settings.filterMode);
        dispatch('addLoading');
        await dispatch('fetchManufactures');
        commit('setLoaded', true);
        dispatch('removeLoading');
      }
    },
    async fetchManufactures({ dispatch, commit}) {
      try {
        commit('catalogFilter/setLoading', true, { root: true });
        commit('setLoading', true);
        const response = await AutoParamService.fetchManufacturers();
        await commit('setManufacturer', response.data);
      } catch (e) {
        dispatch('notifications/showErrorNotification', '', { root: true });
      }
      commit('setLoading', false);
      commit('catalogFilter/setLoading', false, { root: true });
    },
    async fetchPossibleModels({ state, dispatch, commit }) {
      try {
        commit('catalogFilter/setLoading', true, { root: true });
        commit('setLoading', true);
        commit('resetModels');
        commit('resetGenerations');
        commit('resetModifications');
        const response = await AutoParamService.fetchModels(state.autoFields.manufacturer.current_value);
        await commit('setModels', response.data);
      } catch (e) {
        dispatch('notifications/showErrorNotification', '', { root: true });
      }
      commit('setLoading', false);
      commit('catalogFilter/setLoading', false, { root: true });
    },
    async fetchPossibleGenerations({ state, dispatch, commit }) {
      try {
        commit('catalogFilter/setLoading', true, { root: true });
        commit('setLoading', true);
        commit('resetGenerations');
        commit('resetModifications');
        const response = await AutoParamService.fetchGenerations(state.autoFields.model.current_value);
        await commit('setGenerations', response.data);
      } catch (e) {
        dispatch('notifications/showErrorNotification', '', { root: true });
      }
      commit('setLoading', false);
      commit('catalogFilter/setLoading', false, { root: true });
    },
    async fetchPossibleModifications({ state, dispatch, commit }) {
      try {
        commit('catalogFilter/setLoading', true, { root: true });
        commit('setLoading', true);
        commit('resetModifications');
        const response = await AutoParamService.fetchModifications(state.autoFields.generation.current_value);
        await commit('setModifications', response.data);
      } catch (e) {
        dispatch('notifications/showErrorNotification', '', { root: true });
      }
      commit('setLoading', false);
      commit('catalogFilter/setLoading', false, { root: true });
    },
    async fetchPossibleModificationsByModel({ state, dispatch, commit }) {
      try {
        commit('catalogFilter/setLoading', true, { root: true });
        commit('setLoading', true);
        commit('resetModifications');

        const response = await AutoParamService.fetchModificationsByModel(state.autoFields.model.current_value);

        await commit('setModifications', response.data);
      } catch (e) {
        dispatch('notifications/showErrorNotification', '', { root: true });
      }
      commit('setLoading', false);
      commit('catalogFilter/setLoading', false, { root: true });
    },
    async fetchSizes({ state, dispatch, commit, rootState }) {
      try {
        commit('catalogFilter/setLoading', true, { root: true });
        commit('setLoading', true);

        const response = await AutoParamService.fetchSizes(
          rootState.catalogFilter.settings.productType,
          state.autoFields.modification.current_value,
        );

        commit('setExtend', true);
        commit('setParamFields', response.data.fields);

        commit('setCurrentModification', {
          name: response.data.modificationName,
          info: response.data.modificationInfo,
        });

        const { count } = response.data;
        commit('catalogFilter/setTotalProducts', count, { root: true });
        dispatch('fetchProducts');
      } catch (e) {
        dispatch('notifications/showErrorNotification', '', { root: true });
      }

      commit('setLoading', false);
      commit('catalogFilter/setLoading', false, { root: true });
    },
    async debounceUpdatePossibleFields({ dispatch }) {
      this.debounceOrder = this.debounceOrder ? this.debounceOrder + 1 : 1;
      setTimeout(() => {
        this.debounceOrder -= 1;
        if (!this.debounceOrder) {
          dispatch('updatePossibleFields');
        }
      }, 1000);
    },
    async updatePossibleFields({ commit, state, rootState, dispatch }) {
      dispatch('addLoading');

      try {
        const payload = FieldsPayload.getPayload(state.paramFields);

        const { productType } = rootState.catalogFilter.settings;
        const modificationId = state.autoFields.modification.current_value;

        const result = await AutoParamService.fetchSizes(
          productType,
          modificationId,
          payload,
        );

        const { count, fields } = result.data;
        commit('catalogFilter/setTotalProducts', count, { root: true });

        if (fields) {
          const updatedFields = FieldsUpdater.updateFields(state.paramFields, fields);
          await commit('setParamFields', updatedFields);

          dispatch('checkHidePrice');
        }
      } catch (e) {
        dispatch('notifications/showErrorNotification', '', { root: true });
      }

      dispatch('removeLoading');
    },
    async fetchProducts({ state, dispatch, commit, rootState }) {
      try {
        commit('catalogFilter/setProductPreloader', true, { root: true });
        const { productType } = rootState.catalogFilter.settings;
        const modificationId = state.autoFields.modification.current_value;

        const payload = FieldsPayload.getPayload(state.paramFields);
        payload.order = rootState.catalogFilter.currentOrder;

        const result = await AutoParamService.fetchProducts(productType, modificationId, payload);
        await dispatch('catalogFilter/replaceCatalogContent', result.data, { root: true });
      } catch (error) {
        dispatch('notifications/showErrorNotification', '', { root: true });
      }
      commit('catalogFilter/setProductPreloader', false, { root: true });
      document.dispatchEvent(new Event('DOMContentMutated'));
    },
    async resetFields({ dispatch }) {
      await dispatch('fetchSizes');
    },
    async checkHidePrice({ state, rootState, dispatch }) {
      const hidePriceField = state.paramFields.find((field) => field.key === 'hide_price');
      if (hidePriceField && hidePriceField.current_value !== rootState.catalogFilter.hidePrice) {
        dispatch('catalogFilter/updateHidePrice', hidePriceField.current_value, { root: true });
      }
    },
    handleCustomField({ dispatch }, field) {
      switch (field.key) {
        case 'hide_price':
          dispatch('checkHidePrice');
          break;
      }
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setLoaded(state, loaded) {
      state.loaded = loaded;
    },

    setExtend(state, extend) {
      state.extend = extend;
    },
    setParamFields(state, fields) {
      state.paramFields = fields;
    },
    setAutoFields(state, fields) {
      state.autoFields = fields;
    },
    setCurrentModification(state, modificationInfo) {
      state.currentModification = modificationInfo;
    },

    setManufacturer(state, manufactures) {
      state.autoFields.manufacturer.values = manufactures;
    },
    setModels(state, models) {
      state.autoFields.model.values = models;
    },
    setGenerations(state, generations) {
      state.autoFields.generation.values = generations;
    },
    setModifications(state, generations) {
      state.autoFields.modification.values = generations;
    },

    resetModels(state) {
      state.autoFields.model.current_value = null;
      state.autoFields.model.values = [];
    },
    resetGenerations(state) {
      state.autoFields.generation.current_value = null;
      state.autoFields.generation.values = [];
    },
    resetModifications(state) {
      state.autoFields.modification.current_value = null;
      state.autoFields.modification.values = [];
    },
    setFilterMode(state, filterMode) {
      state.filterMode = filterMode;
    },
  },
};

export default AutoParam;