<template>
  <div class="catalog-auto-filter">
    <form action="" class="catalog-auto-filter__top" @submit.prevent="fetchSizes">
      <div class="catalog-auto-filter__fields">
        <div class="catalog-auto-filter__field" v-for="field in arrayAutoFields">
          <BaseFieldSelectFilterable
              :values="field.values"
              :field-id="field.key"
              :currentValue="field.current_value"
              :disabled="!field.values.length"
              :label="field.label"
              v-model="field.current_value"
              :no-default="true"
              :default-value="{ name: '', value: null }"
              @input="(e) => change(field.key, e)"
              :placeholder="field.placeholder"
              :has-filter="true"
          />
        </div>
      </div>

      <div class="catalog-auto-filter__button-wrapper">
        <button class="catalog-auto-filter__button" :disabled="!enableButton">
          Подобрать
        </button>
      </div>
    </form>

    <TheFilterAutoParam v-if="extend"/>
  </div>
</template>

<script>
import TheFilterAutoParam from './TheFilterAutoParam.vue';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import BaseFieldSelectFilterable from '../parts/fields/BaseFieldSelectFilterable.vue';

export default {
  name: "TheFilterAuto",
  components: {
    TheFilterAutoParam,
    BaseFieldSelectFilterable
  },
  computed: {
    ...mapState('autoParam', {
      'extend': 'extend',
      'autoFields': 'autoFields',
    }),
    ...mapGetters('autoParam', {
      'arrayAutoFields': 'arrayAutoFields',
      'enableButton': 'enableButton'
    }),
  },
  methods: {
    ...mapActions('autoParam', {
      'loadManufactures': 'loadManufactures',
      'fetchPossibleModels': 'fetchPossibleModels',
      'fetchPossibleGenerations': 'fetchPossibleGenerations',
      'fetchPossibleModifications': 'fetchPossibleModifications',
      'fetchPossibleModificationsByModel': 'fetchPossibleModificationsByModel',
      'fetchSizes': 'fetchSizes',
      'fetchProducts': 'fetchProducts',
    }),
    async fetchParamSizes() {
      await this.fetchSizes();
      await this.fetchProducts();
    },
    async change(key, value) {
      if (value) {
        switch (key) {
          case 'auto-manufacturer':
            await this.fetchPossibleModels();
            break;
          case 'auto-model':
            await this.fetchPossibleGenerations();
            console.log('generation fetched')
            await this.fetchPossibleModificationsByModel();
            console.log('modification fetched')
            break;
          case 'auto-generation':
            await this.fetchPossibleModifications();
            break;
          case 'auto-modification':
            break;
        }
      }
    }
  },
  mounted() {
    this.loadManufactures();
  }
}
</script>