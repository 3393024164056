<template>
  <form class="catalog-filter-short" :class="classModes" @submit.prevent="submit">
    <div class="catalog-filter-short__content">
      <div class="catalog-filter-short__field" v-for="field in getFields" :class="`catalog-filter-short__field_${field.key}`">
        <BaseFieldSwitch
            v-if="field.view_type === 'switch'"
            :input-id="field.key"
            v-model="field.current_value"
            :label="field.label"
            @change="switchEvent(field)"
        />
        <BaseFieldSelect
            v-else-if="field.view_type === 'select'"
            :values="field.values"
            :field-id="field.key"
            :label="field.label"
            :currentValue="field.current_value"
            :disable-values="field.disable_values"
            v-model="field.current_value"
            @input="change"
        />

        <BaseFieldSelectMulti
            v-else-if="field.view_type === 'select-multi'"
            :values="field.values"
            :field-id="field.key"
            :label="field.label"
            :currentValues="field.current_value"
            :disable-values="field.disable_values"
            :has-filter="field.has_filter"
            :data-type="field.data_type"
            @input="debounceChange"
        />

        <div v-else-if="field.view_type === 'text'" class="catalog-filter-short__text-field">
          <label v-if="field.label" :for="field.key">
            {{ field.label }}
          </label>
          <input
              :id="field.key"
              :placeholder="field.placeholder"
              type="text"
              autocomplete="off"
              class="catalog-filter-short__text-input"
              v-model="field.current_value"
              @input="debounceChange"
          >
        </div>

        <BaseFieldCheckboxList
            v-else-if="field.view_type === 'checkbox-list'"
            :input-id="field.key"
            :current-values="field.current_value"
            :possible-values="field.values"
            :disabled-values="field.disable_values"
            @change="debounceChange"
        />

        <BaseFieldCheckbox
            v-else-if="field.view_type === 'checkbox'"
            :label="field.label"
            :input-id="field.key"
            :value="field.current_value"
            v-model="field.current_value"
            :disabled="field.disabled"
            @change="change(field)"
        />

        <BaseFieldSelectMulti
            class="catalog-filter-short__second-axis-field"
            v-if="field.has_second_axis && enableSecondAxis && field.view_type === 'select-multi'"
            :values="field.values"
            :field-id="`second_${field.key}`"
            :label="field.label + ' (вторая ось)'"
            :currentValues="field.current_second_value"
            :disable-values="field.disable_values"
            :has-filter="field.has_filter"
            :data-type="field.data_type"
            @input="debounceChange"
        />
      </div>


      <div class="catalog-filter-short__button-wrap catalog-filter-short__button-wrap_submit">
        <button class="catalog-filter-short__button button" :disabled="disableSubmit">
          Подобрать
        </button>
      </div>

      <div class="catalog-filter-short__button-wrap catalog-filter-short__button-wrap_reset">
        <button type="button" class="catalog-filter-short__button button button__secondary" @click="resetFilters">
          Сбросить фильтры
        </button>
      </div>

      <div class="catalog-filter-short__axis-button-wrap">
        <GrayPlusButton
            class="catalog-filter-short__axis-button"
            :text="'Добавить ось'"
            :text-alt="'Убрать ось'"
            :is-open="enableSecondAxis"
            @click="switchSecondAxis"
        />
      </div>
    </div>
  </form>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BaseFieldSelect from "../parts/fields/BaseFieldSelect.vue";
import BaseFieldSelectMulti from "../parts/fields/BaseFieldSelectMulti.vue";
import BaseFieldCheckboxList from '../parts/fields/BaseFieldCheckboxList.vue';
import BaseFieldCheckbox from '../parts/fields/BaseFieldCheckbox.vue';
import GrayPlusButton from '../parts/GrayPlusButton.vue';
import BaseFieldSwitch from '../parts/fields/BaseFieldSwitch.vue';


export default {
  name: "TheFilterParamShort",
  components: {
    BaseFieldSelect,
    BaseFieldCheckboxList,
    BaseFieldCheckbox,
    BaseFieldSelectMulti,
    GrayPlusButton,
    BaseFieldSwitch
  },
  // data() {
  //   return {
  //     enableSecondAxis: false
  //   };
  // },
  computed: {
    ...mapState('filterParam', {
      'fields': 'fields',
      'enableSecondAxis': 'enableSecondAxis',
    }),
    ...mapState('catalogFilter', {
      'totalProducts': 'totalProducts',
      'settings': 'settings'
    }),
    ...mapGetters('filterParam', {
      'inchField': 'inchField'
    }),
    ...mapState('targetClient', {
      'managerLoaded': 'loaded'
    }),
    getFields() {
      const groups = [
        'main-top',
        'main-bottom',
        'main-additional',
        'main-over',
        'main-over-additional',
        'main-inch'
      ];
      if (this.managerLoaded) {
        groups.push('main-manager');
      }
      const fields = this.fields
          .filter((field) => groups.includes(field.group))
          .filter((field) => (field.view_type !== 'checkbox-list' || field.values.length > 1))
          .sort((a, b) => {
            if (a.order === b.order) {
              return 0;
            }

            return a.order > b.order ? 1 : -1;
          });


      const inchField = this.inchField;
      if (!inchField) {
        return fields;
      } else if (inchField.current_value) {
        return fields.filter((field) => field.size_type !== 'metric');
      } else {
        return fields.filter((field) => field.size_type !== 'inch');
      }
    },
    productType() {
      return this.settings ? this.settings.productType : '';
    },
    classModes() {
      const classes = [];
      if (this.settings) {
        classes.push(`_${this.settings.productType}`)
      }

      classes.push(this.managerLoaded ? '_manager' : '_user');

      return classes.join(' ');
    },

    disableSubmit() {
      return !this.totalProducts;
    }
  },
  methods: {
    ...mapActions('filterParam', {
      'updatePossibleFields': 'updatePossibleFields',
      'debounceUpdatePossibleFields': 'debounceUpdatePossibleFields',
      'fetchProducts': 'fetchProducts',
      'handleCustomField': 'handleCustomField',
      'resetFields': 'resetFields',
      'setEnableSecondAxis': 'setEnableSecondAxis'
    }),
    submit(e) {
      e.preventDefault();
      if (this.totalProducts) {
        this.fetchProducts();
      }
    },
    change(field) {
      if (field.data_type === 'custom') {
        this.handleCustomField(field);
      } else {
        this.updatePossibleFields();
      }
    },
    debounceChange() {
      this.debounceUpdatePossibleFields();
    },
    resetFilters() {
      this.resetFields();
    },
    switchSecondAxis() {
      this.setEnableSecondAxis(!this.enableSecondAxis);
    },
    switchEvent(field) {
      console.log(field)
      if (field.key === 'inch') {
        // this.setCurrentValue({
        //   fieldKey: this.inchField.key,
        //   value
        // });
        this.updatePossibleFields()
      }
    }

  }
}
</script>