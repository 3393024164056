import FieldsPayload from '../../parts/fields-payload';
import FilterCollectionService from '../../services/filterCollection.service';
import FieldsSetter from '../../parts/fields-setter';

const FilterCollection = {
  namespaced: true,
  state: {
    filtersLists: {
      personal: [],
      general: [],
    },
  },
  getters: {
  },
  actions: {
    async save({ rootState, dispatch }, formData) {
      const { mode } = rootState.catalogFilter;
      const { productType } = rootState.catalogFilter.settings;
      const data = {
        filter_name: formData.filterName,
        comment: formData.comment,
        categoryId: formData.categoryId,
        // delete_at: formData.delete_at,
        order: rootState.catalogFilter.currentOrder,
      };

      let success = false;
      try {
        if (mode === 'param') {
          data.filter_data = FieldsPayload.getPayload(rootState.filterParam.fields);
          data.filter_data.enable_second_axis = rootState.filterParam.enableSecondAxis;
        } else if (mode === 'auto') {
          data.filter_data = FieldsPayload.getPayload(rootState.autoParam.paramFields);
          data.filter_auto_data = {};
          Object.entries(rootState.autoParam.autoFields).forEach((field) => {
            if (!field[1].current_value) {
              throw new Error('Произошла ошибка');
            }
            data.filter_auto_data[field[0]] = field[1].current_value;
          });
        }

        const response = await FilterCollectionService.save(mode, productType, data);
        success = response.data.state === 'success';
      } catch (e) {
        success = false;
      }

      if (success) {
        dispatch('notifications/showSuccessNotification', 'Параметры поиска успешно сохранены', { root: true });
      } else {
        dispatch('notifications/showErrorNotification', '', { root: true });
      }

      return success;
    },
    async fetchList({ commit, rootState, dispatch }) {
      const { mode } = rootState.catalogFilter;
      const { productType } = rootState.catalogFilter.settings;
      const { categoryId } = rootState.catalogFilter.settings;

      try {
        const response = await FilterCollectionService.list(mode, productType, categoryId);
        commit('setFiltersList', response.data);
      } catch (e) {
        dispatch('notifications/showErrorNotification', '', { root: true });
      }
    },
    async apply({ rootState, dispatch }, filterId) {
      const { mode } = rootState.catalogFilter;
      const { productType } = rootState.catalogFilter.settings;

      const response = await FilterCollectionService.apply(mode, productType, filterId);

      if (mode === 'param') {
        await dispatch('applyParamSave', response.data);
      } else if (mode === 'auto') {
        await dispatch('applyAutoSave', response.data);
      } else {
        dispatch('notifications/showErrorNotification', '', { root: true });
        return;
      }

      dispatch('notifications/showSuccessNotification', 'Параметры поиска применены', { root: true });
    },
    async delete({ rootState, dispatch }, filterId) {
      const { mode } = rootState.catalogFilter;
      const { productType } = rootState.catalogFilter.settings;

      const response = await FilterCollectionService.delete(mode, productType, filterId);
      if (response.data.success) {
        dispatch('notifications/showSuccessNotification', 'Фильтр удален', { root: true });
        return true;
      }

      dispatch('notifications/showErrorNotification', '', { root: true });
      return false;
    },
    async applyParamSave({ dispatch, rootState, commit }, data) {
      await dispatch('filterParam/resetFields', null, { root: true });

      const secondField = data.filter_data.find((field) => field.key === 'enable_second_axis')

      const stateFields = FieldsSetter.setFields(rootState.filterParam.fields, data.filter_data);
      await dispatch('filterParam/setEnableSecondAxis', secondField ? secondField.value : false, { root: true });

      await commit('filterParam/setFields', stateFields, { root: true });

      await dispatch('filterParam/updatePossibleFields', null, { root: true });
      await dispatch('filterParam/fetchProducts', null, { root: true });
    },
    async applyAutoSave({ dispatch, rootState, commit }, data) {
      const stateAutoFields = rootState.autoParam.autoFields;
      stateAutoFields.manufacturer.current_value = data.filter_auto_data.manufacturer;
      stateAutoFields.model.values = data.autoList.model;
      stateAutoFields.model.current_value = data.filter_auto_data.model;
      stateAutoFields.generation.values = data.autoList.generation;
      stateAutoFields.generation.current_value = data.filter_auto_data.generation;
      stateAutoFields.modification.values = data.autoList.modification;
      stateAutoFields.modification.current_value = data.filter_auto_data.modification;

      commit('autoParam/setAutoFields', stateAutoFields, { root: true });
      await dispatch('autoParam/resetFields', null, { root: true });

      const paramFields = FieldsSetter.setFields(rootState.autoParam.paramFields, data.filter_data);
      await commit('autoParam/setParamFields', paramFields, { root: true });
      await dispatch('autoParam/updatePossibleFields', null, { root: true });
      await dispatch('autoParam/fetchProducts', null, { root: true });
    },
  },
  mutations: {
    setFiltersList(state, lists) {
      state.filtersLists = lists;
    },
  },
};

export default FilterCollection;