import StatService from '../../services/stat.service';
import StatClientService from '../../services/statClient.service';

const StatModule = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
  },
  actions: {
    async loadStatData({ }, { statDataKey, params }) {
      let result = null;
      switch (statDataKey) {
        case 'products_by_categories_graph': result = await StatService.fetchProductsByCategories(params); break;
        case 'offers_by_categories_graph': result = await StatService.fetchOffersByCategories(params); break;

        case 'total_products_by_categories_table': result = await StatService.fetchTotalProductsByCategories(params); break;
        case 'total_offers_by_categories_table': result = await StatService.fetchTotalOffersByCategories(params); break;
        case 'total_providers_by_categories_table': result = await StatService.fetchTotalProvidersByCategories(params); break;
        case 'total_storages_by_categories_table': result = await StatService.fetchTotalStoragesByCategories(params); break;

        case 'offers_by_storages_table': result = await StatService.fetchOffersByStorages(params.extra.type, params); break;
        case 'products_by_storages_table': result = await StatService.fetchProductsByStorages(params.extra.type, params); break;
        case 'offers_by_providers_table': result = await StatService.fetchOffersByProviders(params.extra.type, params); break;
        case 'products_by_providers_table': result = await StatService.fetchProductsByProviders(params.extra.type, params); break;

        case 'clients_by_day_graph': result = await StatClientService.fetchClientsByDay(params); break;
        case 'client_prices_by_day_graph': result = await StatClientService.fetchClientPricesByDay(params); break;
        case 'client_prices_total_by_day_graph': result = await StatClientService.fetchClientPricesTotalByDay(params); break;
        case 'client_views_table': result = await StatClientService.fetchClientViews(params); break;
        case 'client_carts_table': result = await StatClientService.fetchClientCarts(params); break;
        case 'client_orders_table': result = await StatClientService.fetchClientOrders(params); break;

        case 'durations-graph': result = await StatService.fetchDurations(params); break;

        default: result = null;
      }
      if (result) {
        if (params.extra && params.extra.type) {
          statDataKey = `${params.extra.type}_${statDataKey}`;
        }

        return result.data;
      }

      return null;
    },
  },
};

export default StatModule;