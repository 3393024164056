<template>
  <div class="catalog-filter-collection">
    <div class="catalog-filter-collection__buttons-wrap">
      <a href="javascript:void(0);" class="catalog-filter-collection__link"
         v-if="showSaveButton"
         @click="openModal('save_filter')"
      >
        <span class="catalog-filter-collection__link-icon">
          <save-icon/>
        </span>
        <span class="catalog-filter-collection__link-text">
          Сохранить поиск
        </span>
      </a>

      <a href="javascript:void(0);" class="catalog-filter-collection__link" @click="openList">
        <span class="catalog-filter-collection__link-icon">
          <filter-icon/>
        </span>
        <span class="catalog-filter-collection__link-text">
          Применить сохраненный фильтр
        </span>
      </a>

      <a href="javascript:void(0);" class="catalog-filter-collection__link" @click="fetchCsv" v-if="managerLoaded">
        <span class="catalog-filter-collection__link-icon">
          <download-icon/>
        </span>
        <span class="catalog-filter-collection__link-text">
          Выгрузить в CSV
        </span>
      </a>
    </div>

    <div class="catalog-filter-collection__modals-wrap">
      <Popup
          :opened="isModalOpened('save_filter')"
          @close="closeModal('save_filter')"
          :is-processing="isProcessing('save_filter')"
      >
        <form class="catalog-filter-collection__modal-form" @submit.prevent="save">
          <div class="catalog-filter-collection__title">
            Сохранить параметры поиска
          </div>

          <div class="catalog-filter-collection__subtitle">
            Введите название для текущего набора фильтров и сохраните для последующего использования
          </div>

          <input
              type="text"
              autocomplete="off"
              placeholder="Название фильтра"
              class="catalog-filter-collection__input"
              v-model="filterName"
          >

          <textarea
              v-if="managerLoaded"
              class="catalog-filter-collection__comment"
              placeholder="Комментарий..."
              v-model="comment"
          />

          <button class="catalog-filter-collection__button button" :disabled="isProcessing('save_filter')">
            Сохранить
          </button>
        </form>
      </Popup>

      <Popup
          :opened="isModalOpened('apply_filter')"
          :is-processing="isProcessing('apply_filter')"
          @close="closeModal('apply_filter')"
      >
        <form class="catalog-filter-collection__modal-form" @submit.prevent="applyFilter">
          <div class="catalog-filter-collection__title">
            Применить сохраненные фильтры
          </div>

          <ul class="catalog-filter-collection__tabs tabs tabs_small">
            <li class="tabs__tab" :class="{'_active': personalIsActive}">
              <a href="javascript:void(0);" class="tabs__tab-link" @click="setCurrentTab('personal')">
                 Мои фильтры
              </a>
            </li>
            <li class="tabs__tab" :class="{'_active': generalIsActive}">
              <a href="javascript:void(0);" class="tabs__tab-link" @click="setCurrentTab('general')">
                 Общие фильтры
              </a>
            </li>
          </ul>

          <div class="catalog-filter-collection__selector-wrap">
            <div class="catalog-filter-collection__selector" v-if="currentList && currentList.length">
              <base-field-select
                  :values="currentList"
                  :current-value="selectedFilter"
                  v-model="selectedFilter"
                  :no-default="true"
                  field-id="'filter_save_list'"
              />
            </div>
            <div class="catalog-filter-collection__empty" v-else>
              Нет сохраненных фильтров
            </div>
          </div>

          <button class="catalog-filter-collection__button button"
              :disabled="isProcessing('apply_filter') || !selectedFilter"
          >
            Применить
          </button>

          <button type="button" v-if="personalIsActive" class="catalog-filter-collection__button button button__secondary"
              :disabled="isProcessing('apply_filter') || !selectedFilter"
                  @click="deleteFilter"
          >
            Удалить
          </button>
        </form>
      </Popup>
    </div>
  </div>
</template>

<script>
import SaveIcon from '../icons/SaveIcon.vue';
import FilterIcon from '../icons/FilterIcon.vue';
import DownloadIcon from '../icons/DownloadIcon.vue';
import modalMixin from '../../mixins/modal';
import Popup from '../common/Popup.vue';
import BaseFieldSelect from "../parts/fields/BaseFieldSelect.vue";
import BaseFieldDate from "../parts/fields/BaseFieldDate.vue";
import {mapGetters, mapState, mapActions} from "vuex";

export default {
  name: "TheFilterCollection",
  components: {
    SaveIcon,
    DownloadIcon,
    FilterIcon,
    Popup,
    BaseFieldSelect,
    BaseFieldDate
  },
  data() {
    return {
      filterName: '',
      comment: '',
      currentTab: 'personal',
      selectedFilter: null,
      // delete_at: null
    };
  },
  mixins: [
    modalMixin
  ],
  computed: {
    ...mapState('filterCollection', {
      'filtersLists': 'filtersLists',
    }),
    ...mapGetters('catalogFilter', {
      'isAutoFilter': 'isAutoFilter',
      'isParamFilter': 'isParamFilter',
      'categoryId': 'categoryId'
    }),
    ...mapState('autoParam', {
      'autoFields': 'autoFields'
    }),
    ...mapState('targetClient', {
      'managerLoaded': 'loaded'
    }),
    showSaveButton() {
      return this.isParamFilter || this.isAutoFilter && this.autoFields.modification.current_value;
    },
    hasPersonal() {
      return !!this.filtersLists.personal.length;
    },
    hasGeneral() {
      return !!this.filtersLists.general.length;
    },
    personalIsActive() {
      return this.currentTab === 'personal';
    },
    generalIsActive() {
      return this.currentTab === 'general';
    },
    currentList() {
      return this.currentTab ? this.filtersLists[this.currentTab] : null;
    }
  },
  methods: {
    ...mapActions('filterCollection', {
      'filterSave': 'save',
      'fetchList': 'fetchList',
      'apply': 'apply',
      'delete': 'delete'
    }),
    ...mapActions('filterParam', {
      'fetchCsv': 'fetchCsv'
    }),
    async save() {
      if (this.filterName) {
        this.setProcessing('save_filter');
        const success = await this.filterSave({
          filterName: this.filterName,
          comment: this.comment,
          categoryId: this.categoryId
          // delete_at: this.delete_at
        });
        this.unsetProcessing('save_filter');
        if (success) {
          this.setSuccess('save_filter')
        }

        this.closeModal('save_filter');
      }
    },
    async openList() {
      this.openModal('apply_filter');
      this.setProcessing('apply_filter');
      await this.fetchList();
      this.defineCurrentTab();
      this.unsetProcessing('apply_filter');
    },
    async applyFilter() {
      this.setProcessing('apply_filter');
      const success = await this.apply(this.selectedFilter);
      this.unsetProcessing('apply_filter');
      if (success) {
        this.setSuccess('apply_filter')
      }
      this.closeModal('apply_filter');
    },
    async deleteFilter() {
      this.setProcessing('apply_filter');
      const success = await this.delete(this.selectedFilter);
      if (success) {
        await this.fetchList();
        this.defineCurrentSelect();
      }


      this.unsetProcessing('apply_filter');
    },
    setCurrentTab(tab) {
      this.currentTab = tab;
      if (tab) {
        this.defineCurrentSelect();
      }
    },
    defineCurrentTab() {
      if (this.hasPersonal) {
        this.setCurrentTab('personal');
      } else if (this.hasGeneral) {
        this.setCurrentTab('general');
      } else {
        this.setCurrentTab(null);
      }
    },
    defineCurrentSelect() {
      if (this.currentList && this.currentList.length) {
        this.selectedFilter = this.currentList[0].value;
      } else {
        return this.selectedFilter = null;
      }
    }
  }
}
</script>
