import axios from 'axios';

const FilterParamService = {
  async fetchPossibleFields(productType, params) {
    console.log(params)
    const url = `/catalog/api/fetch_possible_fields/${productType}`;
    return axios.post(url, params);
  },
  async fetchProducts(productType, params) {
    const url = `/catalog/api/fetch_products/${productType}`;
    return axios.get(url, { params });
  },
  async fetchCsv(productType, params) {
    const url = `/catalog/api/csv/${productType}`;
    return axios.get(url, { params });
  },
};

export default FilterParamService;